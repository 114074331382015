import React, { useContext, useEffect, useState } from 'react';
import './SwitchNow.scss';
import { AccordionGroup, AccordionItem } from '../Common/AccordionGroup';
import Button from '../Common/Button';
import { AccordionContext } from '../../utils/AccordionContext';
import { CommonDataContext } from '../../utils/CommonDataContext';
import Tariffs from './Tariffs';
import { renderToStaticMarkup } from 'react-dom/server';

const PlanDetailsBreakdown = ({ planDetails, eligibilityIsConfirmed, currentPlan }) => {
    return (
        <div className="switch-now-section content-tile primary">
            <h2 id="switch-now">Switch Now</h2>
            <SwitchViaEmail planDetails={planDetails} eligibilityIsConfirmed={eligibilityIsConfirmed} currentPlan={currentPlan} />
        </div>
    )

    /*
    return (
        <div className="switch-now-section content-tile primary">
            <h2 id="switch-now">Switch Now</h2>
            <AccordionGroup>
                <AccordionItem id="switch-via-email" label="Switch via email" icon="email" iconClass="on-disc">
                    <SwitchViaEmail planDetails={planDetails} eligibilityIsConfirmed={eligibilityIsConfirmed} />
                </AccordionItem>
                <AccordionItem id="switch-online" label="Switch online" icon="online" iconClass="on-disc">

                </AccordionItem>
            </AccordionGroup>
        </div>
    );*/
};

const SwitchViaEmail = ({ planDetails, eligibilityIsConfirmed, currentPlan }) => {
    const { userProfile, authenticatedUser } = useContext(CommonDataContext);
    const { selectedItem, setSelectedItem } = useContext(AccordionContext);
    const [copyButtonIcon, setCopyButtonIcon] = useState("copy");
    const [userPhoneNumber, setUserPhoneNumber] = useState('');

    const isNewProvider = planDetails.brand != currentPlan.brand;
    const cdrPlanId = planDetails.planId;
    const [aerPlanId, regExtension] = cdrPlanId.split('@');
    const emailBody = isNewProvider ? `Hi ${planDetails.brandName},%0D%0A
%0D%0A
This request is for the ${planDetails.brandName} sales team.%0D%0A
%0D%0A
I would like to sign up to the ${planDetails.displayName} plan, specifically the plan variant with AER plan ID of ${aerPlanId}.%0D%0A
%0D%0A
${generateRatesTableForEmail(planDetails)}
Details of this specific plan can be viewed here: https://app.automised.energy/plans/${cdrPlanId}%0D%0A
%0D%0A
This email has been generated by Automised Energy (https://automised.energy) who independently analysed my home electricity options and found plan ${aerPlanId} from ${planDetails.brandName} to be a suitable plan for me.%0D%0A
%0D%0A
To complete this sign-up, please contact me on ${userPhoneNumber}.%0D%0A
%0D%0A
I am looking forward to hearing from you soon.%0D%0A
%0D%0A
Regards,%0D%0A
%0D%0A
${authenticatedUser.attributes.name}
`
        :
        `Hi ${planDetails.brandName},%0D%0A
%0D%0A
This request is for the ${planDetails.brandName} customer support team.%0D%0A
%0D%0A
I am a current customer with you and would like to switch from my current plan to the ${planDetails.displayName} plan, specifically the plan variant with AER plan ID of ${aerPlanId}.%0D%0A
%0D%0A
${generateRatesTableForEmail(planDetails)}
Details of this specific plan, including rates, can be viewed here: https://app.automised.energy/plans/${cdrPlanId}%0D%0A
%0D%0A
This email has been generated by Automised Energy (https://automised.energy) who independently analysed my home electricity options based on published retailer plans and found plan ${aerPlanId} from ${planDetails.brandName} to be a suitable plan for me.%0D%0A
%0D%0A
To help me complete this plan switch, please contact me on ${userPhoneNumber}.%0D%0A
%0D%0A
I am looking forward to hearing from you soon.%0D%0A
%0D%0A
Regards,%0D%0A
%0D%0A
${authenticatedUser.attributes.name}
`
    const emailSubject = isNewProvider ?
        `Sign up for plan: ${planDetails.displayName} (${aerPlanId})`
        :
        `Switch to plan: ${planDetails.displayName} (${aerPlanId})`

    const validateForm = (event) => {
        const form = document.getElementById('switch-request-form');

        if (form.checkValidity()) {
            //carry on...
        } else {
            form.reportValidity();
            event.preventDefault();
        }
    }

    return (
        <>
            <div className='switch-via-email'>
                <div className='switch-via-email-header'>
                    <h3>{
                        isNewProvider ?
                            `Switching to ${planDetails.brandName} is simple.`
                            :
                            `Switching between plans at ${planDetails.brandName} is simple.`
                    }</h3>
                </div>

                <p className='step'>We will generate a switching request for you, ready to be sent to <strong>{planDetails.brandName}</strong>. Just enter your phone number to complete:</p>

                <div className='email-generator'>
                    <form id="switch-request-form">
                        <div className='send-summary'>
                            <label><span className='value-label'>Plan name:</span><span className='read-only-text-value'>{planDetails.displayName}</span></label>
                            <label><span className='value-label'>Plan ID:</span><span className='read-only-text-value'>{aerPlanId}</span></label>
                            <label><span className='value-label'>Your name:</span><span className='read-only-text-value'>{authenticatedUser.attributes.name}</span></label>
                            <label><span className='value-label'>Your phone:</span><input type='tel' placeholder='Phone number' required={true} onChange={(el) => { setUserPhoneNumber(el.currentTarget.value); }}></input></label>
                            {eligibilityIsConfirmed ?
                                <Button type='primary' icon='email' label='Generate switch request' clickHandler={(event) => { validateForm(event); }} href={`mailto:${planDetails.switchEmail}?subject=${emailSubject}&body=${emailBody}`} />
                                :
                                <Button type="primary" className={`switch-now`} label="Check eligibility" href="#eligibility" clickHandler={() => setSelectedItem('eligibility')} />
                            }
                        </div>
                    </form>
                    <div className='email-preview'>
                        <span className='preview-hint' onClick={() => { document.getElementById('email-copy').style.display = document.getElementById('email-copy').style.display == "none" ? "block" : "none"; }}>Having trouble sending the request? See request email here</span>
                        <div id='email-copy' style={{ display: "none" }}>
                            <pre className='email-content' id='email-content'>{`To: ${planDetails.switchEmail}

Subject: ${emailSubject}

Body:

${decodeURI(emailBody.replace(/%0D%0A\s%0D%0A/g, '%0D%0A'))}`}
                            </pre>
                            <Button icon={copyButtonIcon} label="Copy email to clipboard" type="tertiary" clickHandler={(el) => { navigator.clipboard.writeText(emailBody.replace(/%0D%0A\s%0D%0A/g, '\r\n')).then(() => { setCopyButtonIcon('check') }).catch((err) => { console.error(err) }); el.preventDefault(); }} />
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <hr />
                <h2>The switching process</h2>
                <p className='step'>Important: Your power supply will <strong>not</strong> be cut off during the switching process.</p>
                <AccordionGroup>
                    <AccordionItem id="switch-request" label={`You request the switch to ${planDetails.brandName}`} icon="arrow_forward">
                        <p>{
                            isNewProvider ?
                                `When you click "Generate request" (above), you are provided with a pre-populated email requesting to sign-up to this plan. This email is ready for you to send to ${planDetails.brandName}. You provide your address details directly to ${planDetails.brandName} in the next step.`
                                :
                                `When you click "Generate request" (above), you are provided with a pre-populated email requesting to switch to this plan. This email is ready for you to send to ${planDetails.brandName}. You provide your account details directly to ${planDetails.brandName} in the next step.`
                        }
                        </p>
                    </AccordionItem>
                    <AccordionItem id="provider-contact" label={`${planDetails.brandName} contacts you`} icon="arrow_back">
                        <p>{
                            isNewProvider ?
                                `${planDetails.brandName} will respond to your request, collecting some more information (including address) and stepping you through the process of signing up.`
                                :
                                `${planDetails.brandName} will respond to your request, collecting some more information (such as your account details) and stepping you through the process of switching between plans.`
                        }
                        </p>
                    </AccordionItem>
                    {isNewProvider &&
                        <AccordionItem id="notify-old-provider" label={`${planDetails.brandName} notifies ${currentPlan.brandName}`} icon="more">
                            <p>{planDetails.brandName} will contact {currentPlan.brandName} to notify them of the switch. You will <strong>not</strong> need to contact {currentPlan.brandName} yourself at any stage of the switching process.
                            </p>
                        </AccordionItem>
                    }
                    <AccordionItem id="cooling-off" label="10 day cooling off period" icon="access_time">
                        <p>Switching plans includes a mandatory 10 business-day cooling off period. During this time, you can cancel the contract without any penalty or cost. The cooling-off period begins the day after you receive the contract details and information about your right to cancel. This allows you to change your mind if you find a better deal or if you decide not to switch plans. If you do decide to change your mind during this period, contact {planDetails.brandName} directly to notify them.</p>
                    </AccordionItem>
                    <AccordionItem id="new-plan-starts" label={`Your new plan with ${planDetails.brandName} starts`} icon="check">
                        <p>{isNewProvider ?
                            `Your new plan with ${planDetails.brandName} will start and, at the same time, your previous plan with ${currentPlan.brandName} will end. This represents the start of your new billing cycle with ${planDetails.brandName}.`
                            :
                            `Your new plan with ${planDetails.brandName} will start and, at the same time, your previous plan with ${currentPlan.brandName} will end. From this point on you will be billed according to the rates of the new plan.`
                        }
                        </p>
                    </AccordionItem>
                    {isNewProvider &&
                        <AccordionItem id="final-bill" label={`${currentPlan.brandName} sends their final bill`} icon="check">
                            <p>{currentPlan.brandName} will send a bill for services up to the date your new plan with {currentPlan.brandName} started. To finalise your relationship with {currentPlan.brandName}, you need to pay this bill. </p>
                        </AccordionItem>
                    }
                </AccordionGroup>
            </div>
        </>
    )
}

function generateRatesTableForEmail(planDetails) {
    const reactComponent = <Tariffs planDetails={planDetails} noCTAs={true} textOnly={true} />;
    //console.log(reactComponent);
    const staticMarkup = renderToStaticMarkup(reactComponent).replace(/\\n/g, '\n').replace(/\n/g, '%0D%0A');
    //console.log(staticMarkup)

    return staticMarkup;
}

export default PlanDetailsBreakdown;